<template lang="pug">
  v-container.privacy-policy.mt-4.mx-auto(fluid)
    v-slide-y-transition(mode='out-in')
      v-card
        v-card-text
          h1 Privacy Policy
          p This privacy notice discloses the privacy practices for iOS and Android apps developed by Alex Pepper.
          p
            strong Information Collection, Use, and Sharing
          p You are the sole owner of the information collected in my apps. All personal data, such as I Ching journal, MusicWatch artist list, BookWatch author list, and AppWatch publisher list, is stored securely in iCloud or locally on the device. I have no access to that data, and will not sell or rent your information to anyone.
          p If you contact me for support, I will use your email address to respond to you, regarding the reason you contacted me. I will not share your information with any third party outside of our organization.
          p
            strong Security
          p I take precautions to protect your information. When you enter sensitive information into the apps, your information is protected both online and offline.
          p If you feel that I am not abiding by this privacy policy, you should contact me immediately via telephone at (210)884-2123
</template>

<script>
export default {}
</script>

<style scoped>
  .privacy-policy {
    max-width: 480px !important;
  }
</style>
